@import 'assets/style/_commonImport.scss';

header {
  padding: 20px 0;
  border-bottom: 1px solid #e7e7e7;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
}

.logo {
  display: inline-block;
  color: $heading-text;
  font-size: 26px;
  font-weight: 300;
  text-transform: capitalize;

  span {
    color: $clear-theme;
  }
}