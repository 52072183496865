@import 'assets/style/_commonImport.scss';

.submit_button {
  display: flex;
  align-content: center;
  justify-content: center;

  .empty {
    flex: .2 1;
    @media screen and (max-width: $ipadland) { flex: 0.5; } 
    @media screen and (max-width: $ipadport) { display: none; } 
  }

  .button {
    flex: 1 1;
    max-width: 500px;

    @media screen and (max-width: $ipadport) { flex: none; } 
    
    a {
      text-align: center;
    }
  
    p { margin-left: 15px; }
  }
}