@import 'assets/style/_commonImport.scss';

.input-container {
  margin-bottom: 18px; 
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
  height: auto;
  opacity: 1;
  @include vendor(transition, .5s);
  @media screen and (max-width: $ipadport) { 
    flex-direction: column; 
    align-items: normal;
  } 

  .input-label {     
    flex: 0.2;
    @media screen and (max-width: $ipadland) { flex: 0.5; } 

    span{
      display: inline-block;
      color: $text-color;
      font-size: 24px;
      font-family: secondary-selected-font,"Roboto",sans-serif;
      font-weight: 500;
      line-height: 2;
    }
  }

  .input-box {
    max-width: 500px;
    flex: 1;

    @media screen and (max-width: $ipadport) { max-width: 100% } 
  }

  .dropdown {
    &__control {
      padding: 8px 0;
      border-radius: 0;

      &:hover {
        border-color: #ccc;
      }
    }

    &__control--is-focused {
      box-shadow: none;
    }

    &__indicator-separator {
      display: none;
    }

    &__value-container {
      cursor: text
    }

    &__dropdown-indicator {
      color: $primary-text;
      cursor: pointer;

      &:hover {
        color: $primary-color;
      }
    }

    &__multi-value {
      padding: 10px;
      border-radius: 8px;
      // background-color: #f2f2f2;

      &__label {
        // color: #6d6e71;
        font-family: secondary-selected-font,"Roboto",sans-serif;
        font-size: 18px;
        letter-spacing: -.2px;
      }

      &__remove {
        // color: #6d6e71;
        svg {
          width: 18px;
          height: 18px;
        }
        &:hover {
          color: #999;
          cursor: pointer; 
          background: unset;
        }
      }
    }

    &__option {
      &:active {
        color: #fff;
      }

      &--is-focused {
        // color: #fff;
        @media screen and (max-width: $ipadland) { color: #000; } 

      }

      &--is-selected {
        color: #fff;
      }
    }
    &__menu {
      &-list {
        &::-webkit-scrollbar {
          width: 5px;
        }
  
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px grey; 
          border-radius: 10px;
        }
  
        &::-webkit-scrollbar-thumb {
          background: #11233c;
          border-radius: 10px;
        }
      }
    }
  }
}

.paragraph {
  height: auto;
  display: inline-block;
  overflow: visible;
  opacity: 1;
  @include vendor(transition, .5s);
  @media screen and (max-width: $ipadport) { 
    width: 100%;
    margin: auto;
    text-align: center;
  } 

  .input-label {     
    display: inline-block;

    span{
      color: $primary-text;
      font-size: 18px;
      font-family: "Roboto", sans-serif;
      font-weight: 500;
      line-height: 2;
      vertical-align: text-top;

      @media screen and (max-width: $ipadport) { 
        line-height: 2;
      } 
    }
  }

  .input-box {
    position: relative;
    margin: 0 25px 10px 5px;
    display: inline-block;
    min-width: 200px;
    @media screen and (max-width: $ipadport) { margin: 0 25px 25px 5px; } 
    
    &:after {
      content: '';
      height: 3px;
      width: 3px;
      background-color: $primary-text;
      border-radius: 100%;
      overflow: hidden;
      position: absolute;
      bottom: 5px;
      right: -10px;
    }
  }

  .dropdown {
    &__control {
      background-color: transparent;
      border-radius: 0;
    }

    &__single-value {
      top: 65%;
    }

    &__control--is-focused {
      box-shadow: none;
    }

    &__indicator-separator {
      display: none;
    }

    &__value-container {
      cursor: text
    }

    &__placeholder {
      top: 65%;
    }

    &__dropdown-indicator {
      color: $primary-text;
      cursor: pointer;

      &:hover {
        color: $primary-color;
      }
    }

    &__multi-value {
      padding: 2px;
      border-radius: 5px;
      color: #fff;

      &__label {
        color: #fff
      }

      &__remove {
        &:hover {
          color: #ccc;
          cursor: pointer; 
        }
      }
    }

    &__option {
      &:active {
        color: #fff;
      }

      &--is-focused {
        color: #fff;
      }

      &--is-selected {
        color: #fff;
      }
    }
    
    &__menu {
      &-list {
        &::-webkit-scrollbar {
          width: 5px;
        }
  
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px grey; 
          border-radius: 10px;
        }
  
        &::-webkit-scrollbar-thumb {
          background: #11233c;
          border-radius: 10px;
        }
      }
    }
  }
}

.hide {
  @include vendor(transition, .5s);
  opacity: 0;
  overflow: hidden;
  height: 0;
}
