@import 'mixin';
@import 'variables';

/* 
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com 
Twitter: @rich_clark
*/

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
    margin:0;
    padding:0;
    border:0;
    outline:0;
    font-size:100%;
    vertical-align:baseline;
    background:transparent;
}

body {
    line-height:1;
}

article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section { 
    display:block;
}

nav ul {
    list-style:none;
}

blockquote, q {
    quotes:none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content:'';
    content:none;
}

a {
    margin:0;
    padding:0;
    font-size:100%;
    vertical-align:baseline;
    background:transparent;
}

/* change colours to suit your needs */
ins {
    background-color:#ff9;
    color:#000;
    text-decoration:none;
}

/* change colours to suit your needs */
mark {
    background-color:#ff9;
    color:#000; 
    font-style:italic;
    font-weight:bold;
}

del {
    text-decoration: line-through;
}

abbr[title], dfn[title] {
    border-bottom:1px dotted;
    cursor:help;
}

table {
    border-collapse:collapse;
    border-spacing:0;
}

/* change border colour to suit your needs */
hr {
    display:block;
    height:1px;
    border:0;   
    border-top:1px solid #cccccc;
    margin:1em 0;
    padding:0;
}

input, select {
    vertical-align:middle;
}

.cf:before,
.cf:after {
  display: table;
  content: " ";
}

.cf:after { clear: both; }

* {
    box-sizing: border-box;
    outline: none;
}

img { 
    width: 100%; 
    display: block;
}

body {
  font-family: 'Nunito', sans-serif;
}

a {
  display: block;
  color: inherit;
  text-decoration: none;
  @include transition(all);
}

html, body {
    height: 100%;
    @include vendor(scroll-behavior,smooth);
}

#root {
    height: 100%;
    background-color: $background-color;
}

.wrapper {
  max-width: $max-width;
  width: calc( 100% - 50px );
  margin: auto;

  @media screen and (max-width: $ipadport) { width: calc( 100% - 50px ); } 
} 

li {
    list-style: none;
}

* {
    font-family: secondary-selected-font, "Roboto", sans-serif;
}