@import 'assets/style/_commonImport.scss';

.form-container {
  text-align: left;
  @media screen and (max-width: $small-pc) { padding-top: 40px; }
  @media screen and (max-width: $ipadport) { padding-top: 50px; }
  @media screen and (max-width: $phoneport) { padding-top: 100px; }
  @media screen and (max-width: $phone) { padding-top: 120px; }
}

.modal-block {
  padding-top: 20px;
  display: flex;
  .empty {
    flex: .68 1;
  }
  .modal-container {
    flex: 1 1;
  }
}