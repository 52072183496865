$pc: 1440px;
$small-pc: 1280px;
$ipadland: 1024px;
$ipad: 995px;
$ipadport: 768px;
$phoneport: 480px;
$phone: 385px;
// Color 
$clear-theme: #6fd7dc;
$primary-text: #656C83;
$heading-text: #777;
$primary-color: #b75b6b;
$primary-faded: #b75b6b7a;
$background-color: #fff;
$text-color: #18244a;
$placeholder: #9b9b9b;
$brandname: #231f20;

// Width
$max-width: 1400px;