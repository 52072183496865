@import 'assets/style/_commonImport.scss';

.error {
  height: 100vh;

  &-container {
    height: 100vh;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $background-color;
  }

  &-message {
    margin: 10px 0;
    font-size: 28px;
    font-weight: 900;
    color: $primary-text;
  }

  &-icon {
    display: block;
    color: $primary-color;
    font-size: 100px;
  }
}