@import 'assets/style/_commonImport.scss';


.iosBody {
	height: 100vh;
	position: relative;
	overflow: hidden;
}

.listing {
  background-color: $background-color;
  
  .wrapper {
    @media screen and (max-width: $small-pc) { width: calc( 100% - 50px ); } 
  }

  .content {
    display: flex;
  }

  .modal {
    width: 50%;
    margin: 50px 0;
    padding: 20px;
    position: absolute;
    z-index: 100;
    top: 25%;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;

    @media screen and (max-width: $ipad) { font-size: 12px; } 
    @media screen and (max-width: $ipadport) { width: 90%; } 
    
    span {
      line-height: 22px;
    }

    .close {
      cursor: pointer;
      position: absolute;
      display: block;
      padding: 2px 5px;
      line-height: 20px;
      right: -10px;
      top: -10px;
      font-size: 24px;
      background: #ffffff;
      border-radius: 18px;
      border: 1px solid #cfcece;
    }
  }

  // h2 {
  //   margin: 30px 0;
  //   font-size: 36px;
  // }
  h2 {
    max-width: 1400px;
    height: 101px;
    color: $text-color;
    // font-family: 'Crimson Text',sans-serif;
    font-weight: normal;
    line-height: 1.57;
    letter-spacing: -0.2px;
    letter-spacing: -.3px;
    text-align: center;
    span {
      font-style: italic;
    }
    @media screen and (max-width: $ipadport) { 
      width: 100%; 
      height: auto; 
      line-height: 1.2;
      padding-top: 20px;
    }
  }
  
  .main-content {
    width: 76%;
    @media screen and (max-width: $ipad) { width: 100%; } 

    h3 {
      margin: 10px 0;
      font-size: 20px;
    }

    .form-container {
      margin-top: 0;
    }

    .loader-container {
      position: absolute;
      left: 50%;
      bottom: 50px;
    }

    .list-wrapper {
      width: 100%;
      padding: 70px 0;
      display: flex;
      flex-wrap: wrap;
      position: relative;

      .error {
        width: 100%;
        // text-align: center;
        font-size: 22px;
        h3 {
          font-size: 22px;
          font-family: secondary-selected-font, Roboto, sans-serif;
          color: #000;
        }
      }

      .list-container {
        width: 100%;
        padding: 35px 0;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        border-top: 1px solid #ccc;
        position: relative;
      }

      .filter {
        display: none;
        @media screen and (max-width: $ipad) {
          display: block;
          padding: 9px 20px;
          border: 1px solid #ddd;
          position: absolute;
          top: 10px;
          font-size: 19px;
          color: #888;
        }
        @media screen and (max-width: $phone) {
          padding: 9px 9px;
          font-size: 14px;
        }
      }

      .count-container {
        position: absolute;
        top: 20px;
        left: 0;

        @media screen and (max-width: $ipad) {
          top: 80px;
          z-index: 3;          
        }
        > span {
          display: inline-block;
          margin-bottom: 7px;
          font-size: 13px;
          font-family: secondary-selected-font,"Roboto",sans-serif;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: .77;
          letter-spacing: .5px;
          color: #000;
        }
        
        .modal_container {
          width: 100%;
          height: 100%;
          overflow: auto;
          position: fixed;
          z-index: 5;
          top: 0;
          left: 0;
        }
      
      }

      .sort-container {
        width: 30%;
        position: absolute;
        z-index: 4;
        top: 10px;
        right: 0;

        @media screen and (max-width: $small-pc) {
          width: 39%;
        }
      
        @media screen and (max-width: $ipad) {
          min-width: 274px;
          text-align: right;
        }
        @media screen and (max-width: $phone) {
          min-width: 259px;
        }
        .dropdown__menu {
          text-align: center;
        }
        .dropdown {
          width: 62%;
          height: 14px;
          margin-left: -4px;
          display: inline-block;
          font-family: 'Roboto';
          font-size: 12px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.6px;
          color: #222222;
          text-transform: uppercase;
          @media screen and (max-width: $phone) {
            font-size: 11px;
          }
          &__control {
            background-color: transparent;
            border-radius: 0;
          }
      
          &__control--is-focused {
            box-shadow: none;
          }
      
          &__indicator-separator {
            display: none;
          }
      
          &__value-container {
            cursor: pointer
          }
      
          &__dropdown-indicator {
            color: $primary-text;
            cursor: pointer;
      
          }
      
          &__multi-value {
            padding: 2px;
            border-radius: 5px;
            color: #fff;
      
            &__label {
              color: #fff
            }
      
            &__remove {
              &:hover {
                color: #ccc;
                cursor: pointer; 
              }
            }
          }
      
          &__option {
            &:active {
              color: #fff;
            }
      
            &--is-focused {
              color: #fff;
            }
      
            &--is-selected {
              color: #fff;
            }
          }
          .dropdown__menu {
            margin-top: 20px;
          }
        }
        .sort-by-label {
          width: 157px;
          height: 14px;
          color: #222222;
          font-family: secondary-selected-font, "Roboto", sans-serif;
          font-size: 11px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: .6px;
          text-transform: uppercase;
          @media screen and (max-width: $phone) {
            font-size: 10px;
            letter-spacing: 0;
          }
        }
      }

      .list-item {
        @include vendor(transition, .5s);
        @media screen and (max-width: $ipadport) {
          padding-top: 0; 
          padding-bottom: 0; 
        }  
        .list-image {
          padding: 0 30px;
        }
        
        .list-content {
          margin: 20px 20px;
          text-align: center;
          font-size: 20px;
          @include vendor(transition, .5s);
        }

        &:hover {

          .list-content {
            bottom: -5px;
            @include vendor(transition, .5s);
          }  
          background-color: #fff;
          cursor: pointer;
          @include vendor(transition, .5s);
        }
      }    
    }
  }  

  .banner-content {
    margin-bottom: 21px;
    padding: 10px 0 20px;
    // max-height: 180px;
    position: relative;
    text-align: center;

    // &:after {
    //   content: '';
    //   width: 100%;
    //   height: 100%;
    //   display: inline-block;
    //   position: absolute;
    //   z-index: 1;
    //   top: 0;
    //   left: 0;
    //   opacity: .5;
    //   background: #fff;
    // }

    h2 {
      margin: 0;
      color: $text-color;
      position: relative;
      z-index: 3;
      left: 50%;
      transform: translateX(-50%);
      // font-family: 'Crimson Text',sans-serif;
      font-weight: normal;
      letter-spacing: -.3px;
    }
  }
}

.noScroll {
  height: 100%; 
  overflow: hidden;
}
.popup-content {
  @media screen and (max-width: $ipadport) { width: 90% !important; } 
}