@import 'assets/style/_commonImport.scss';

.aside-container {
    width: 24%;
    @media screen and (max-width: $ipad) { display: none; } 
    * {
      font-family: secondary-selected-font, sans-serif;
    }
  }

  .show {
    @media screen and (max-width: $ipad) { 
      width: auto;      
      display: block; 
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 100;
      background-color: #fff;
      overflow: scroll;
    } 

    aside {
      @media screen and (max-width: $ipad) { margin-right: 0; }
      
      .open-filter {
        @media screen and (max-width: $ipad) {
          margin: auto;
          width: 60%;
        }

        @media screen and (max-width: $ipadport) {
          width: 100%;
        }
      }
    }
  }

  .power-label {
    margin: 15px 0;
    text-align: left;
    font-family: secondary-selected-font,"Roboto",sans-serif;  
    font-size: 12px;
    line-height: 1;
    color: #9b9b9b;
    .logo {
      font-size: 12px;
    }
  }
  
  aside {
    background-color: #fff;
    border-radius: 4px;
    margin-right: 30px;
    
    .filter {
      padding: 20px 11px 0;

      @include breakpoint(ipadland) { padding: 10px 10px 15px 15px; }

      .filter-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 21px;

        h3 {
          color: $text-color;
          font-size: 11px;
          font-family: secondary-selected-font,"Roboto",sans-serif;
          font-weight: bold;
          line-height: normal;
          text-transform: uppercase;
        }
      }
    }
    
    h5 {
      &:not(:first-of-type) {
        padding-top: 11px; 
      }
      font-size: 12px;
      font-family: secondary-selected-font,"Roboto",sans-serif;
      font-weight: bold;
      letter-spacing: .5px;
      line-height: 1.92;
    }
    
    .open-filter {
      border-bottom: 1px solid #EDEBEB;

      h4 {
        padding: 20px;
        color: #11233c;
        font-size: 17px;
        font-weight: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: normal;
      }

      .acc-head {
        padding: 0 20px;
        margin: 10px 0 10px;

        span {
          font-family: secondary-selected-font,"Roboto",sans-serif;
          font-size: 15px;
          font-weight: normal;
          font-style: normal;
          line-height: 1.63;
        }
      }

      .acc-content {
        padding: 0 20px;
        margin-bottom: 30px;
      }
      
      .input-container {
        margin-top: 0;
      }

      .dropdown__multi-value {
        display: none;
      }

      .dropdown__clear-indicator {
        display: none;
      }
    }

    .filter-list {
      @include vendor(display, flex);
      @include vendor(flex-flow, row wrap);
      @include vendor(transition, .5s);

      li {
        width: 100%;
        margin: 0 10px 10px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        list-style: none;
    
        .close-icon {
          color: #808080;
          margin-left: 8px;
          font-size: 12px;
        }
      }
    }

    .acc-item {
      margin-bottom: 10px;
      border: 1px solid #edebeb;

      .acc-head {
        width: 100%;
        padding: 9px 11px;
        font-family: secondary-selected-font,"Roboto",sans-serif;
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000;
        letter-spacing: -.2px;
        text-transform: uppercase;
        cursor: pointer;
      
        @include vendor(display, flex);
        @include vendor(align-items, center);
        @include vendor(justify-content, space-between);

        .acc-btn {
          color: #839FC8;
        }

        &:hover { color: #243E63; }
      }

      .acc-content {
        height: 0;
        margin: 0;
        padding: 0 11px;
        overflow: hidden;
        @include vendor(transition, .3s);

        .category {
          width: 100%;
          height: 30px;
          padding: 0 5px;
          margin-bottom: 10px;
          font-family: secondary-selected-font,"Roboto",sans-serif;
          font-size: 12px;
        }

        li {
          font-family: secondary-selected-font,"Roboto",sans-serif;
          font-size: 12px;
          
          label {
            margin-bottom: 5px;
            @include vendor(display, flex);
            @include vendor(align-items, center);
            @include vendor(justify-content, flex-start);
            cursor: pointer;
            color: #11233c;

            span { 
              margin-left: 6px;
              text-transform: capitalize;
              font-size: 12px;
              font-family: secondary-selected-font,"Roboto",sans-serif;
              font-weight: normal;
              font-style: normal;
              line-height: 1.92;
              letter-spacing: -.2px;
            }
          }

          [type="checkbox"] {
            @include vendor(appearance, none);
            width: 15px;
            height: 15px;
            // border: 1px solid #839FC8;
            position: relative;

            &:focus { outline: none; }
          }
          
          [type="checkbox"]:after {
            cursor: pointer;
            width: 18px;
            height: 18px;
            position: absolute;
            left: -1px;
            top: -1px;
            color: #fff;
            text-align: center;
          }
        }

      }

      &.open {
        .acc-head { border-bottom: none; }
        .acc-content { 
          height: 200px; 
          border-bottom: 1px solid #EDEBEB;
          overflow-y: scroll;
          
          &::-webkit-scrollbar {
            width: 5px;
          }

          &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px grey; 
            border-radius: 10px;
          }

          &::-webkit-scrollbar-thumb {
            background: #11233c;
            border-radius: 10px;
          }
        }
      }
    }

    .count-container {
      font-family: secondary-selected-font,"Roboto",sans-serif;
      span { font-family: inherit }
    }
    .map-section {
      min-height: 180px;
      position: relative;
      background: deepskyblue;  /*temporary color replace by map */

      .tag {
        position: absolute;
        left: 0;
        top: 0;
        color: #11233c;

        &.white {
          padding: 0 8px;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          background: rgba(255,255,255,.8);

          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          line-height: 1.63;
          letter-spacing: normal;
          color: #11233c;
        }
      }

      .view-map-cta {
        padding: 5px 20px;
        border: 1px solid #243E63;
        border-radius: 4px;
        display: inline-block;
        position: absolute;
        bottom: 10px;
        left: 50%;
        background-color: #243E63;
        color: #fff;
        @include vendor(transform, translateX(-50%));

        @include breakpoint(ipadland) { padding: 5px 15px; }

        &:hover {
          background: #fff;
          color: #243E63;
        }
      }
    }

    .acc-item {
      &.state {
        .region {
          span {
            &:first-of-type {
              padding: 3px 10px 3px 0;
              border-right: 1px solid #EDEBEB;
            }

            &:last-of-type { margin-left: 6px; }
          }
        }
      }
    }

    .acc-item {
      &.zipcode {
        .acc-content {
          height: auto;
          padding: 0 30px;
          margin: 0;

          @include breakpoint(ipadland) { padding: 0 15px; }

          .side-search {
            position: relative;
            margin-bottom: 10px;

            input {
              width: 100%;
              padding: 5px 16px 5px 55px;
              border-radius: 3px;	
              border: 1px solid #BDBDBD;
            }

            .search-icon {
              position: absolute;
              top: 50%;
              left: 18px;
              color: #BDBDBD;
              font-size: 18px;
              @include vendor(transform, translateY(-50%));
            }
          }

          .zipcodes {
            li {
              label {
                margin-bottom: 5px;
                @include vendor(display, flex);
                @include vendor(align-items, center);
                @include vendor(justify-content, flex-start);
                cursor: pointer;
                color: #11233c;

                span { 
                  margin-left: 6px;
                text-transform: capitalize;
                font-size: 14px;
                font-weight: normal;
                font-style: normal;
                line-height: 1.86;
                letter-spacing: normal;
                color: #11233c;
                }
              }

              [type="checkbox"] {
                @include vendor(appearance, none);
                width: 18px;
                height: 18px;
                border: 1px solid #839FC8;
                border-radius: 4px;
                position: relative;
                margin-left: 10px;

                &:focus { outline: none; }
              }
              
              [type="checkbox"]:after {
                cursor: pointer;
                width: 18px;
                height: 18px;
                position: absolute;
                left: -1px;
                top: -1px;
                color: #fff;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }