@import 'assets/style/_commonImport.scss';

.search {
  @media screen and (max-width: $ipadport) { 
    height: auto;
  }
  // height: 100vh;
  // &:after {
  //   content: '';
  //   width: 100%;
  //   height: 100%;
  //   display: inline-block;
  //   position: absolute;
  //   z-index: 1;
  //   top: 0;
  //   left: 0;
  //   opacity: .5;
  //   background: #fff;
  // }

  .banner-content {
    padding: 0 0 46px;
    position: relative;
    z-index: 3;
    text-align: center;
    sup {
      vertical-align: super;
      font-size: small;
    }
    // .intro {
    //   display: block;
    //   color: $text-color;
    //   font-size: 25px;
    //   font-family: 'Roboto',sans-serif;
    //   line-height: 30px;
    //   letter-spacing: -.2px;
    // }
    .banner-text {
      padding-bottom: 10px;
      @media screen and (max-width: $ipadport) { 
        width: 100%; 
        height: auto; 
        line-height: 1.2;
      }
      p {
        width: 100%;
        // height: 74px;
        color: #18244a;
        // font-family: Roboto, sans-serif;
        line-height: 1.4;
        letter-spacing: -0.2px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        text-align: center;
        span {
          display: block;
        }
        @media screen and (max-width: $ipadport) { 
          padding: 10px 0;
          font-size: 18px;
        }      
        a {
          display: inline-block;
          border-bottom: 1px solid #18244a;
          line-height: 0.9;
        }
      }
    }

    h2 {
      max-width: 1400px;
      height: 101px;
      color: $text-color;
      // font-family: 'Crimson Text',sans-serif;
      // font-size: 67px;
      font-weight: normal;
      // line-height: 1.57;
      padding-top: 10px;
      letter-spacing: -.2px;
      text-align: center;
      span {
        font-style: italic;
      }
      @media screen and (max-width: $ipadport) { 
        width: 100%; 
        height: auto; 
        line-height: 1.2;
        padding-top: 20px;
        font-size: 60px;
      }
    }

  }

  .modal-block {
    padding-top: 20px;
    display: flex;
    .empty {
      flex: .13 1;
      @media screen and (max-width: $ipadland) { flex: .55 1; }
      @media screen and (max-width: $ipad) { flex: .3 1; }
      @media screen and (max-width: $ipadport) { flex: 0 1; }
    }
    .modal-container {
      flex: 1 1;
    }
  }

}
