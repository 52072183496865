@import 'assets/style/_commonImport.scss';

.modal {
  width: 50%;
  margin: 50px 0;
  padding: 20px;
  position: absolute;
  z-index: 100;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;

  @media screen and (max-width: $ipad) { font-size: 12px; } 
  @media screen and (max-width: $ipadport) { width: 90%; } 
  
  span {
    line-height: 22px;
  }
}

.close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

.iosBody {
	height: 100vh;
	position: relative;
	overflow: hidden;
}